import React from 'react';
import './Welcome.css';
import { Header } from '../header/Header';
import { Link, Redirect } from 'react-router-dom'
import { withCookies, useCookies } from 'react-cookie';

const Welcome = () => {

    const [cookies] = useCookies([])

    return (
        <div>
            {
                // CHANGEME auth'd login here
                cookies.token ? <Redirect to="/edit" /> : <Redirect to="/signup" />
                    // <div>
                    //     <Header rhs={
                    //     <Link to={
                    //         {
                    //             pathname: "/login"
                    //         }
                    //     }>
                    //         <h5>Login</h5>
                    //     </Link>
                    // }></Header>
                    //     <div className="row header-content-margin">
                    //         <div >
                    //             <h1 className="header-style">links.farm</h1>
                    //             <div className="subheader section-margin-top">coming soon</div>
                    //         </div>

                    //     </div>
                    // </div>
            }
        </div>
    )
}


export default withCookies(Welcome)