import React from 'react';
import './Edit.css'
import { withCookies, useCookies } from 'react-cookie';
import { useState, useEffect } from 'react'
import { useInputChange } from '../util/hooks'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Header } from '../header/Header'
import { Button, FormLabel } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';


const Edit = ({ api }) => {

    const [cookies] = useCookies([])
    const [userProfile, setUserProfile] = useState(null)
    const [topLinks, setTopLinks] = useState([])
    const [refresh, shouldRefresh] = useState(true)
    const [links, setLinks] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)
    const [swap, setSwap] = useState([])
    const [create, setCreate] = useState(false)
    const [save, setSave] = useState(false)
    const [editIndex, setEditIndex] = useState(-1)
    const [editingLink, setEditingLink] = useState({})
    const [newLink, handleNewLinkChange] = useInputChange({ new_title: "", new_url: "" })

    useEffect(() => {
        (async function fetchMe() {
            try {
                const resp = await api().getMe(cookies.token)
                if (resp && resp.status == 200) {
                    setUserProfile(resp.data)
                }
            } catch (e) {
                setError("Error fetching user")
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            try {
                await api().trackView(`edit/${userProfile.username}`)
            } catch (e) {

            }
        })()
    }, [userProfile])

    useEffect(() => {
        async function fetchLinks() {
            if (!!userProfile) {
                const userId = userProfile.id

                try {
                    const resp = await api().fetchLinksForUser(userId)
                    if (resp && resp.status == 200) {
                        setLinks(resp.data)
                    }
                } catch (e) {
                    setError("Error fetching links")
                }
                setLoading(false)
            }
        }
        fetchLinks()
    }, [userProfile, refresh])

    useEffect(() => {
        (async () => {
            try {
                const resp = await api().getMyTopLinks(cookies.token)
                if (resp && resp.status == 200) {
                    setTopLinks(resp.data)
                } else {
                    setError("Error fetching stats")
                }
            } catch (e) {
                setError("Error fetching stats")
            }
        })()
    }, [refresh])

    useEffect(() => {
        (async function swapLinks() {
            if (swap.length == 2) {
                const first = swap[0]
                const second = swap[1]

                try {
                    const resp = await api().swapLinks(cookies.token, first, second)
                    if (resp && resp.status == 200) {
                        shouldRefresh(!refresh)
                    } else {
                        setError("Error changing links")
                    }
                } catch (e) {
                    setError("Error changing links")
                }

                setSwap([])
            }
        })()
    }, [swap])

    useEffect(() => {
        (async () => {
            if (create) {
                try {
                    const resp = await api().createLink(
                        cookies.token,
                        newLink.new_title,
                        newLink.new_url
                    )

                    if (resp.status === 200) {
                        shouldRefresh(!refresh)
                        setCreate(false)
                    } else {
                        setCreate(false)
                    }
                } catch (e) {
                    setError("Error creating link")
                    setCreate(false)
                }
            }
        })()
    }, [create])


    useEffect(() => {
        (async () => {
            if (save && editingLink.id) {
                try {
                    const resp = await api().editLink(
                        cookies.token,
                        editingLink.id,
                        editingLink.title,
                        editingLink.url
                    )

                    if (resp.status === 200) {
                        shouldRefresh(!refresh)
                        setEditIndex(-1)
                        setSave(false)
                    } else {
                        shouldRefresh(!refresh)
                        setEditIndex(-1)
                        setSave(false)
                    }
                } catch (e) {
                    setError("Error saving link")
                    shouldRefresh(!refresh)
                    setEditIndex(-1)
                    setSave(false)
                }
            }
        })()
    }, [save])



    const canCreate = () => newLink.new_title && newLink.new_url

    return (
        <div className="flex">
            <Header
                lhsText={`Your links`}
                lhsPath={userProfile ? `/${userProfile.username}` : '/'}
                rhs={""} />
            <div className="link-list header-content-margin">
                <div className="message">{error && error}</div>
                <h4>Your top links</h4>
                {topLinks.map(link =>
                    <div>{link.title} - {link.count}</div>)
                }
                <h4 className="margin-top">Edit your links</h4>
                {links.length == 0 && !loading ? <div>You have no links yet!</div> : null}
                {links.map((link, index) =>
                    <div key={index} className="list-item list-row">
                        <FontAwesomeIcon className="arrow-button" icon="arrow-up"
                            onClick={() => {
                                if (index > 0) {
                                    setEditIndex(-1)
                                    setSwap([links[index - 1]._id, link._id])
                                }
                            }} />
                        <FontAwesomeIcon className="arrow-button" icon="arrow-down"
                            onClick={() => {
                                if (index < links.length - 1) {
                                    setEditIndex(-1)
                                    setSwap([link._id, links[index + 1]._id])
                                }
                            }} />

                        {index == editIndex ?
                            <FormGroup controlId="new" className="link-button">
                                <FormControl
                                    name="edit_title"
                                    type="text"
                                    value={editingLink.title}
                                    onChange={e => {
                                        setEditingLink({
                                            ...editingLink,
                                            title: e.target.value
                                        })
                                    }}
                                />
                                <FormControl
                                    name="edit_url"
                                    value={editingLink.url}
                                    type="text"
                                    onChange={e => {
                                        setEditingLink({
                                            ...editingLink,
                                            url: e.target.value
                                        })
                                    }}
                                />
                            </FormGroup> :
                            <Button
                                className="link-button"
                                href={link.url}
                                target="_blank"
                                key={link.id}>{link.title}</Button>
                        }
                        {index == editIndex ?
                            <div>
                                <FontAwesomeIcon className="arrow-button" icon="times-circle"
                                    onClick={() => {
                                        setEditingLink({})
                                        setEditIndex(-1)
                                    }} />

                                <FontAwesomeIcon className="arrow-button" icon="check"
                                    onClick={() => {
                                        setSave(true)
                                    }} />

                            </div>
                            :
                            <FontAwesomeIcon className="arrow-button" icon="edit"
                                onClick={() => {
                                    setEditingLink(link)
                                    setEditIndex(index)
                                }} />
                        }
                    </div>)}
                <h4 className="margin-top">Add new link:</h4>
                <FormGroup controlId="new">
                    <FormControl
                        autoFocus
                        name="new_title"
                        type="text"
                        placeholder="My Site"
                        onChange={handleNewLinkChange}
                    />
                    <FormControl
                        autoFocus
                        name="new_url"
                        placeholder="https://www..."
                        type="text"
                        onChange={handleNewLinkChange}
                    />
                </FormGroup>
                <Button disabled={!canCreate()} onClick={() => setCreate(true)}>Add</Button>
                {!!userProfile ? <div>
                    <center className="section-margin-top margin-page-bottom">
                        <h6>Copy to share:</h6>
                        <h4>{`https://links.farm/${userProfile.username}`}</h4>
                    </center>
                </div> : <div />}
            </div>
        </div>
    )
}

export default withCookies(Edit)