import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export default function api() {
    // CHANGEME your heroku instance here....
    const baseUrl = 'https://linksfarm-api.herokuapp.com'
    // const baseUrl = 'http://localhost:3002'

    return {
        submitLogin: async (email, password) => {
            return axios.post(`${baseUrl}/auth/login`, {
                email: email,
                password: password
            })
        },
        signup: async (firstName, lastName, email, username, password) => {
            return axios.post(`${baseUrl}/register/email`, {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                username: username,
            })
        },
        getMe: async (authToken) => {
            return axios.get(`${baseUrl}/users/me`, {
                headers: {
                    'x-access-token': authToken
                }
            })
        },
        fetchProfile: async (username) => {
            return axios.get(`${baseUrl}/users/profile/${username}`)
        },
        fetchLinksForUser: async (userId) => {
            return axios.get(`${baseUrl}/links/user/${userId}`)
        },
        trackLinkClick: async (linkId) => {
            return axios.post(`${baseUrl}/links/track`, {
                linkId: linkId
            })
        },
        trackView: async (url) => {
            return axios.post(`${baseUrl}/track/view`, {
                url: url
            })
        },
        // edit stuff
        createLink: async (authToken, title, url) => {
            return axios.post(`${baseUrl}/links/create`, {
                title: title,
                url: url
            },
                {
                    headers: {
                        'x-access-token': authToken
                    }
                }
            )
        },     
        editLink: async (authToken, id, title, url) => {
            return axios.post(`${baseUrl}/links/edit`, {
                id: id,
                title: title,
                url: url
            },
                {
                    headers: {
                        'x-access-token': authToken
                    }
                }
            )
        },
        swapLinks: async (authToken, first, second) => {
            return axios.post(`${baseUrl}/links/swap`, {
                first: first,
                second: second
            },
                {
                    headers: {
                        'x-access-token': authToken
                    }
                }
            )
        },
        // stats
        getMyTopLinks: async (authToken) => {
            return axios.get(`${baseUrl}/stats/top`, {
                headers: {
                    'x-access-token': authToken
                }
            }) 
        }
    }
}
