import React, { useEffect } from 'react'
import './Login.css'
import { useInputChange } from '../util/hooks'
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap"
import { Header } from '../header/Header'
import { Link, Redirect } from 'react-router-dom'
import { useState } from 'react'
import { withCookies, Cookies, useCookies } from 'react-cookie'

const LoginForm = ({ api }) => {
    const [values, handleInputChange] = useInputChange({ email: "", password: "" })
    const [submit, setSubmit] = useState(null)
    const [loginResponse, setLoginResponse] = useState({ login: false, message: "" })
    const [cookies, setCookie] = useCookies([])

    useEffect(() => {
        (async () => {
            try {
                await api().trackView(`login`)
            } catch (e) {

            }
        })()
    }, [])

    useEffect(() => {
        async function postLogin() {
            if (submit) {
                try {
                    const resp = await api().submitLogin(values.email, values.password)
                    if (resp.status === 200) {
                        setCookie('token', resp.data.authToken)
                        setLoginResponse({
                            login: true,
                            username: resp.data.user.username,
                            accessToken: resp.data.authToken
                        })
                    } else {
                        setLoginResponse({ login: false, message: "There was an error logging in" })
                        setSubmit(false)
                    }
                } catch (e) {
                    console.log(e)
                    setLoginResponse({ login: false, message: "There was an error logging in" })
                    setSubmit(false)
                }
            }
        }
        postLogin()
    }, [submit])

    const canSubmit = () => values.email && values.password

    return (
        <div>
            {loginResponse.login ? <Redirect to={`/${loginResponse.username}`} /> :
                <div className="Login">
                    <form >
                        <FormGroup controlId="email">
                            <FormLabel>Email</FormLabel>
                            <FormControl
                                autoFocus
                                name="email"
                                type="email"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="password">
                            <FormLabel>Password</FormLabel>
                            <FormControl
                                onChange={handleInputChange}
                                name="password"
                                type="password"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button block disabled={!canSubmit()} onClick={() => setSubmit(true)}  >
                                Log in
             </Button>
                        </FormGroup>
                        <FormGroup>
                            <Link className="spacing-top" to={{ pathname: "/signup" }}>
                                <Button className="spacing-top" block variant="light" >
                                    Sign up
                 </Button>
                            </Link>
                        </FormGroup>
                        {loginResponse.message ? <FormGroup> {loginResponse.message} </FormGroup> : null}
                        <FormGroup></FormGroup>
                    </form>
                </div>}
        </div>

    )
}

const Login = withCookies(({ api }) => (
    <div>
        <Header />
        <LoginForm api={api} />
    </div>
))

export default Login