import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Welcome from './Welcome/Welcome';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCog,
  faUserCircle,
  faArrowUp,
  faArrowDown,
  faEdit,
  faCheck,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import Login from './login/Login';
import Profile from './profile/Profile';
import api from './util/api'
import Signup from './signup/Signup';
import Edit from './edit/Edit';

library.add(faCog, faUserCircle, faArrowUp, faArrowDown, faEdit, faCheck, faTimesCircle)

ReactDOM.render(
  <CookiesProvider>
    <Router>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route path="/login" component={() => <Login api={api} />} />
        <Route path="/signup" component={() => <Signup api={api} />} />
        <Route path="/edit" component={() => <Edit api={api} />} />
        <Route exact path="/:username" component={() => <Profile api={api} />} />
      </Switch>
    </Router>
  </CookiesProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
