import { useState } from 'react'

export const useInputChange = (initial) => {
    const [input, setInput] = useState(initial)

    const handleInputChange = e => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    return [input, handleInputChange]
}