import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

export const Header = ({ lhsText, lhsPath, rhs, }) => (
    <div className="header d-inline-flex black-link">
        <h5>
            <Link to={
                {
                    pathname: lhsPath || "/"
                }
            }>
                <b>{lhsText || "links.farm"}</b>
            </Link>
        </h5>
        {rhs}
    </div>
)