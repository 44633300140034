import React from 'react';
import './Profile.css'
import { withCookies, useCookies } from 'react-cookie';
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Header } from '../header/Header'
import { Button } from 'react-bootstrap';


const Profile = ({ api }) => {

    const { username } = useParams()
    const [cookies] = useCookies([])
    const [userProfile, setUserProfile] = useState(null)
    const [links, setLinks] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                await api().trackView(`profile/${username}`)
            } catch (e) {

            }
        })()
    }, [])

    useEffect(() => {
        async function fetchProfile() {
            try {
                const resp = await api().fetchProfile(username)
                if (resp && resp.status == 200) {
                    setUserProfile(resp.data)
                }
            } catch (e) {
                setError("Error fetching profile")
            }
        }
        fetchProfile()
    }, [])

    useEffect(() => {
        async function fetchLinks() {
            if (!!userProfile) {
                const userId = userProfile.id

                try {
                    const resp = await api().fetchLinksForUser(userId)
                    if (resp && resp.status == 200) {
                        setLinks(resp.data)
                    }
                } catch (e) {
                    setError("Error fetching links")
                }
                setLoading(false)
            }
        }
        fetchLinks()
    }, [userProfile])

    return (
        <div className="flex">
            <Header
                lhsText={`${username}'s links`}
                rhs={
                    cookies.token ?
                        <Link to={
                            {
                                pathname: "/edit"
                            }
                        }>
                            <h5></h5>
                        </Link>
                        :
                        <Link to={
                            {
                                pathname: "/signup"
                            }
                        }>
                            <h5>Sign up</h5>
                        </Link>
                } />
            <div className="link-list header-content-margin">
                <div className="message">{error && error}</div>
                {links.length == 0 && !loading ? <div>This user has no links yet :(</div> : null}
                {links.map(link => <Button
                    className="list-item"
                    href={link.url}
                    target="_blank"
                    onClick={() => api().trackLinkClick(link.id)}
                    key={link.id}>{link.title}</Button>)}
                {!!userProfile ? <div>
                    <center className="section-margin-top">
                        <h5>{`https://links.farm/${userProfile.username}`}</h5>
                    </center>
                </div> : <div />}
            </div>
        </div>
    )
}

export default withCookies(Profile)